@tailwind base;
@tailwind components;
@tailwind utilities;


/*reset rules or default styles applied to plain HTML elements*/
@layer base {}

/*class-based styles that you want to be able to override with utilities*/
@layer components {
    .glassmorphismBlack {
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 8px 0 rgba(31, 61, 56, 1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.18);
    }

    .card {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
    }

    .menuActive {
        background-color: gray;
        opacity: 0.5;
        padding: 10px;
    }

    .menuUnActive {
        background-color: none;
        padding: 10px;
    }
}

/*single-purpose classes that should always take precedence over any other styles*/
@layer utilities {}

html {
    font-size: clamp(11px, .8vw, 18px);
}

.statusIcon {
    top: 2rem;
    left: 2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
    opacity: 1;
}

.bgPositive {
    background: #0A8845 0% 0% no-repeat padding-box;
    border: 0.4rem solid #0A8845;
}

.bgNegative {
    background: #C41313 0% 0% no-repeat padding-box;
    border: 0.4rem solid #C41313;
}

.bgNeutral {
    background: #CC840D 0% 0% no-repeat padding-box;
    border: 0.4rem solid #CC840D;
}

.bgGov {
    background: #0050EF 0% 0% no-repeat padding-box;
    border: 0.4rem solid #0050EF;
}

.bgAdmk {
    background: #398D43 0% 0% no-repeat padding-box;
    border: 0.4rem solid #398D43;
}

.bgBjp {
    background: #B9632E 0% 0% no-repeat padding-box;
    border: 0.4rem solid #B9632E;
}

.bgOthers {
    background: #AB57CB 0% 0% no-repeat padding-box;
    border: 0.4rem solid #AB57CB;
}

.bgBreak {
    background: repeating-linear-gradient(125deg,
            transparent,
            transparent 2px,
            rgb(0, 0, 0) 1px,
            #FF8181 5px),
        linear-gradient(to bottom,
            rgba(0, 0, 0, 0.05),
            rgba(0, 0, 0, 0.05));
    border: 0.1rem solid #FF8181;
}

.bgEvent {
    background: #6DEFFC 0% 0% no-repeat padding-box;
    border: 0.1rem solid #6DEFFC;
}