.topNews span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; 
}


.newsItem {
    opacity: 1;
    border: 1px solid #70707072;
}