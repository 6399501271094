.header {
    border-radius: 8px;
    position: sticky;
    top:0;
    z-index: 99;
    }
    ::-webkit-calendar-picker-indicator {
           filter: invert(1);
    }
    .date-input{
        
        background:none;
        color:#fff;
        border-radius:.5rem;
       
    }